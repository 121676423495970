import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
export default function FeaturedScholarships() {

  const data = useSelector(
    (state: RootState) => state.scholarship.featuredScholarships
  );
  if (!data) {
    return null; 
  }
  return (
    <div className="container scholarships-section my-8">
      <div className="flex flex-col">
      <div className="text-[#383838] font-['Roboto'] text-[36px] font-medium leading-[50px]">
      Featured Scholarships</div>
        <div className="flex flex-row mt-8 overflow-hidden whitespace-nowrap">
          {data.map((item) => (
            <a href="" key={item.id} className="inline-block mx-2">
              <img
                src={item.logo}
                alt={`Logo ${item.id}`}
                className="w-full h-full max-w-[100px] max-h-[46px] flex-shrink-0"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
