// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dream-big {
  color: #FFF;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/screens/scholarship/landing/components/Banner.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEJ,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAA","sourcesContent":[".dream-big{\n    color: #FFF;\n\nfont-family: Roboto;\nfont-size: 40px;\nfont-style: normal;\nfont-weight: 300;\nline-height: 32px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
