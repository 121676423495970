import React, { useState } from "react";
import UniversityShortlistingImg from "../../images/study-abroad/Student/UniversityShortlisting-img.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import { LeadGenerationPopup } from "../../screens/colleges-new/landing/components/WebinarAndArticles";

interface UniversityShortlistingProps {
  data: {
    title: string;
    description: string;
    buttonText: string;
  };
}

const UniversityShortlisting: React.FC<UniversityShortlistingProps> = ({
  data,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section className="bg-[#FFF9E5] py-9">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row-reverse items-center gap-10 ">
          <div className="w-full lg:w-1/2 lg:mr-[6%]">
            <div className="flex flex-col gap-7 lg:text-right">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-medium lg:font-light text-blue">
                  {data.title}
                </h3>
                <div className="flex flex-col">
                  <p className="text-base text-black">{data.description}</p>
                </div>
              </div>
              <div className="flex flex-row lg:justify-end">
                <button
                  onClick={handlePopup}
                  className="hover:bg-blue text-white py-3.5 px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-base font-semibold"
                >
                  {data.buttonText} <EpRight />
                </button>
                <LeadGenerationPopup
                  isOpen={isPopupVisible}
                  onClose={() => setIsPopupVisible(!isPopupVisible)}
                />
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4 xl:w-1/2 hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex w-full h-full">
              <img
                src={UniversityShortlistingImg}
                alt={data.title}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniversityShortlisting;
