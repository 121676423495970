import {
  faChevronLeft,
  faChevronRight,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import NavHeader from "./NavHeader";
interface IProps {
  navLinks: { name: string; icon: ReactNode; link: string }[];
}

const CollapsibleSideBar: FC<IProps> = ({ navLinks }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="relative flex flex-col h-screen gap-2 p-4 bg-white border-r shadow text-grey">
      <NavHeader expanded={expanded} setExpanded={setExpanded} />
      <div className="mt-2"></div>
      {navLinks.map((item) => (
        <NavLink
          to={item.link}
          className={({ isActive }) =>
            `px-2.5 py-2 flex items-center ${
              isActive ? "bg-[#e6e6e6] text-black font-bold" : ""
            } rounded-lg flex gap-4`
          }
          style={{
            transition: "width 0.5s",
            width: expanded ? "15rem" : "2.25rem",
          }}
        >
          {item.icon}
          <span
            className="w-full overflow-hidden text-sm"
            style={{
              transition: "opacity 0.5s",
              opacity: expanded ? 1 : 0,
            }}
          >
            {item.name}
          </span>
        </NavLink>
      ))}
      {!expanded && (
        <button
          className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full shadow -right-3 top-4"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </button>
      )}
    </div>
  );
};

export default CollapsibleSideBar;
