import { useDispatch, useSelector } from "react-redux";
import ProfileDetailCard from "./ProfileDetailCard";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect } from "react";
import { getMyProfile, getOptionData } from "./myProfileThunk";

export enum FormInput {
  Text = "TEXT",
  Date = "DATE",
  Radio = "RADIO",
  Dropdown = "DROPDOWN",
}

const MyProfile = () => {
  const dispatch = useDispatch<AppDispatch>();

  const optionData = useSelector(
    (state: RootState) => state.myProfile.optionData
  );

  const PROFILE_DETAIL_SCHEMA = [
    {
      name: "Basic Details",
      subCategory: [
        {
          fields: [
            {
              key: "firstName",
              name: "First Name",
              type: FormInput.Text,
              required: true,
            },
            {
              key: "lastName",
              name: "Last Name",
              type: FormInput.Text,
              required: true,
            },
            {
              key: "email",
              name: "Email",
              type: FormInput.Text,
              required: true,
              validationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            },
            {
              key: "mobileNumber",
              name: "Mobile number",
              type: FormInput.Text,
              required: true,
              validationRegex: /^[0-9]{10}$/,
            },
          ],
        },
      ],
    },
    {
      name: "Educational Preferences",
      subCategory: [
        {
          fields: [
            {
              key: "currentLevelOfStudy",
              name: "Level",
              type: FormInput.Radio,
              required: true,
              options: optionData?.currentLevelOfStudy?.map((item: string) => ({
                key: item,
                name: item,
              })),
            },
            {
              key: "lookingForDegree",
              name: "Degree",
              type: FormInput.Radio,
              required: true,
              options: optionData?.lookingForDegree?.map((item: string) => ({
                key: item,
                name: item,
              })),
            },
            {
              key: "lookingForStream",
              name: "Stream",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.lookingForStream?.map(
                (item: { streamId: number; streamName: string }) => ({
                  key: item.streamId.toString(),
                  name: item.streamName,
                })
              ),
            },
            {
              key: "lookingForCourse",
              name: "Course",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.lookingForCourse?.map(
                (item: { courseId: number; courseName: string }) => ({
                  key: item.courseId.toString(),
                  name: item.courseName,
                })
              ),
            },
          ],
        },
      ],
    },
    {
      name: "Others",
      subCategory: [
        {
          name: "Contact details",
          fields: [
            {
              key: "city",
              name: "City",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.cities?.map(
                (item: { cityId: number; cityName: string }) => ({
                  key: item.cityId.toString(),
                  name: item.cityName,
                })
              ),
            },
            {
              key: "state",
              name: "State",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.states?.map(
                (item: { stateId: number; stateName: string }) => ({
                  key: item.stateId.toString(),
                  name: item.stateName,
                })
              ),
            },
          ],
        },
        {
          name: "Miscellaneous",
          fields: [
            {
              key: "gender",
              name: "Gender",
              type: FormInput.Radio,
              required: true,
              options: [
                { key: "male", name: "Male" },
                { key: "female", name: "Female" },
                { key: "other", name: "Other" },
              ],
            },
            {
              key: "dob",
              name: "DOB",
              type: FormInput.Date,
              required: false,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getOptionData());
  }, []);

  return (
    <div className="flex flex-col gap-4 lg:gap-8 p-4 lg:p-8">
      {PROFILE_DETAIL_SCHEMA.map((item) => (
        <ProfileDetailCard {...item} />
      ))}
    </div>
  );
};

export default MyProfile;
