import React from "react";

const courses1 = [
  "Diploma",
  "B.E / B.TECH",
  "M.E/ M.TECH",
  "BBA",
  "MBA",
  "B.PHARM",
  "M.PHARM",
  "MBBS",
  "MD",
  "MS",
  "BDS",
  "MDS",
  "B.ARCH",
  "M.ARCH",
  "B.DES",
  "M.DES",
  "BA",
  "MA",
  "BSC",
  "MSC",
  "B.COM",
  "M.COM",
  "BCA",
  "MCA",
  "LLB",
  "LLM",
];

const DreamCourses: React.FC = () => {
  return (
    <div className="flex flex-col text-sm font-bold text-black my-6">
      <div className="flex flex-col justify-center items-center px-20 py-6 w-full bg-[#F7F7F7] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full  max-md:max-w-full">
          <h1 className="self-center ml-2.5 text-4xl text-center text-black">
            Dream Courses
          </h1>
          <div className="flex flex-wrap gap-4 justify-center font-normal items-center mt-7 leading-8 text-center max-md:mr-2.5 max-md:max-w-full">
            {courses1.map((course, index) => (
              <button
                key={index}
                className="gap-2.5 self-stretch px-5 py-0.5 my-auto whitespace-nowrap bg-white border border-solid border-[#747474] min-h-[36px] rounded-[50px]"
              >
                {course}
              </button>
            ))}
          </div>

          <button className="flex gap-2 justify-center items-center self-center button-styles arrow-button white-color map-mentor  mt-8 ml-3 text-base text-white bg-blue-800 rounded-[50px] max-md:px-5">
            <span className="self-stretch my-auto">View all courses</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DreamCourses;
