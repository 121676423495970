import {
  faChevronLeft,
  faChevronRight,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NavHeaderProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavHeader: React.FC<NavHeaderProps> = ({ expanded, setExpanded }) => {
  return (
    <>
      <div
        style={{
          transition: "width 0.5s",
          width: expanded ? "15rem" : "2.5rem",
        }}
        className="flex items-center gap-4 overflow-hidden"
      >
        <img src="/images/CM.png" alt="Icon" className="w-[36px] h-[36px]" />

        <div
          className="flex flex-col"
          style={{
            transition: "opacity 0.5s",
            opacity: expanded ? 1 : 0,
          }}
        >
          <div className="font-bold text-[12px] whitespace-nowrap">
            College Mentor
          </div>
          <div className="text-grey text-[10px] whitespace-nowrap">
            Education panel
          </div>
        </div>

        <button
          className="ml-auto"
          style={{
            transition: "opacity 0.5s",
            opacity: expanded ? 1 : 0,
          }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <FontAwesomeIcon icon={faBars} className="text-[16px]" />
        </button>
      </div>
    </>
  );
};

export default NavHeader;
