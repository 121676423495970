import React, { useState } from "react";
import BackgroundPersonalisedGuidance from "../../images/study-abroad/Student/Background-Personalised-Guidance.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import { LeadGenerationPopup } from "../../screens/colleges-new/landing/components/WebinarAndArticles";

interface PersonalisedGuidanceProps {
  data: {
    title: string;
    items: {
      checkBoxText: string;
    }[];
    buttonText: string;
  };
}

const PersonalisedGuidance: React.FC<PersonalisedGuidanceProps> = ({
  data,
}) => {
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(data.items.length).fill(false)
  );
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedCheckedItems = checkedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setCheckedItems(updatedCheckedItems);
  };

  return (
    <div
      className="py-14 lg:pt-40 lg:pb-9 w-full h-full text-white bg-center bg-no-repeat relative"
      style={{
        backgroundImage: `url(${BackgroundPersonalisedGuidance})`,
        backgroundSize: "cover",
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-[#000000] opacity-60 z-0"></div>
      <div className="container mx-auto relative z-10">
        <div className="flex flex-col items-start gap-8 ">
          <h2 className="text-4xl lg:text-5xl font-bold">{data.title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 lg:w-1/2">
            {data.items.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={checkedItems[index]}
                  onChange={() => handleCheckboxChange(index)}
                  className="w-8 h-8 bg-white flex-shrink-0"
                />
                <p className="text-lg text-white">{item.checkBoxText}</p>
              </div>
            ))}
          </div>
          <button
            onClick={handlePopup}
            className="hover:bg-blue text-white py-3.5 px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-base font-semibold"
          >
            {data.buttonText} <EpRight />
          </button>
          <LeadGenerationPopup
            isOpen={isPopupVisible}
            onClose={() => setIsPopupVisible(!isPopupVisible)}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalisedGuidance;
