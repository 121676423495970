

import React from 'react';

const OneStopSolution = () => {
  return (
    <div className='bg-[#F5F5F5] py-12'>
    <div className=" container flex flex-col ">
      <div className="flex-1 p-4 md:text-center gap-4"> 
        <h1 className="text-#383838 text-center font-open-sans text-[24px] md:text-[36px] font-bold md:leading-[50px]">Your One-Stop Solution for Easy Scholarship and Loan Applications</h1>
        <p className="mt-2 text-#383838 md:text-center font-open-sans text-base font-normal md:leading-8">With our College Mentor platform, we’ve launched Map My Scholarship to make education accessible and affordable
             for all students.<br/>Our mission is to help you achieve your educational dreams in just 6 easy steps.</p>
      </div>
      <div className="flex-1 mt-4">
        <img
          src="/images/scholarship/landing/onestop.png"
          alt="One Stop Solution"
          className="w-full h-auto" 
        />
      </div>
    </div></div>
  );
};

export default OneStopSolution;