import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const updateMyProfile = createAsyncThunk(
  "myProfile/updateUser",
  async (profileData: { [key: string]: string | number }) => {
    const response = await axios.post(
      "https://mentormenteedev.com/api/auth/updateuser",
      profileData,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }
    );
    return response?.data;
  }
);

export const getMyProfile = createAsyncThunk("myProfile/getUser", async () => {
  const response = await axios.get(
    "https://mentormenteedev.com/api/auth/user/profile",
    {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
    }
  );

  if (!response) return null;

  const data = response.data;
  return {
    email: data?.contactDetails?.email,
    mobileNumber: data?.contactDetails?.mobileNumber,
    state: data?.contactDetails?.state?.stateId,
    city: data?.contactDetails?.city?.cityId,
    gender: data?.basicProfile?.gender,
    dob: data?.basicProfile?.dob,
  };
});

export const getOptionData = createAsyncThunk(
  "myProfile/optionData",
  async () => {
    const response = await axios.get(
      "https://mentormenteedev.com/api/auth/signup-info",
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }
    );

    return response?.data;
  }
);
