import { FC, useEffect } from "react";
import { FormInput } from "./MyProfile";
import { useForm, UseFormRegister } from "react-hook-form";
import Modal from "../../../components/Modal";
import { getMyProfile, updateMyProfile } from "./myProfileThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";

interface FormData {
  [field: string]: string | number;
}

const DYMMY_API_RESPONSE: {
  [key: string]: string;
} = {
  gender: "male",
  email: "barath2001.j@gmail.com",
  city: "tamil-nadu",
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  subCategory: {
    name?: string;
    fields: {
      key: string;
      name: string;
      type: FormInput;
      required: boolean;
      options?: { key: string; name: string }[];
      validationRegex?: RegExp;
    }[];
  }[];
}

const ProfileDetailEditModal: FC<IProps> = ({
  isOpen,
  onClose,
  name,
  subCategory,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const userData = useSelector((state: RootState) => state.myProfile.userData);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  const onSubmit = async (data: FormData) => {
    // const updatedData: { [key: string]: string | number } = {};
    // Object.entries(data).forEach(([key, value]) => {
    //   if (!userData[key] || userData[key] != value) updatedData[key] = value;
    // });

    await dispatch(updateMyProfile(data));
    dispatch(getMyProfile());
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form
        className="w-[90vw] lg:w-[60vw] bg-white p-6 lg:p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-xl font-bold">{name}</h2>
        <div className="divide-y">
          {subCategory.map((subCategory) => (
            <div className="py-4">
              {subCategory.name && (
                <h3 className="text-md font-medium text-grey mb-1">
                  {subCategory.name}
                </h3>
              )}
              <div className="grid lg:grid-cols-2 gap-4">
                {subCategory.fields.map((field) => {
                  return (
                    <div>
                      <div className="mb-2">{field.name}</div>
                      <FieldInput
                        fieldKey={field.key}
                        {...field}
                        register={register}
                        errors={errors[field.key]?.message || ""}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="text-base font-semibold text-white px-12 py-2 bg-blue mx-auto rounded"
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default ProfileDetailEditModal;

const FieldInput = ({
  fieldKey,
  name,
  type,
  required,
  options,
  validationRegex,
  register,
  errors,
}: {
  fieldKey: string;
  name: string;
  type: FormInput;
  required: boolean;
  options?: { key: string; name: string }[];
  validationRegex?: RegExp;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  const requiredProp = required ? `${name} is required` : false;

  switch (type) {
    case FormInput.Text:
      return (
        <>
          <input
            {...register(fieldKey, {
              required: requiredProp,
              pattern: validationRegex && {
                value: validationRegex,
                message: `Invalid ${name}`,
              },
            })}
            className="w-full p-2 rounded border border-grey-3"
          />
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Date:
      return (
        <>
          <input
            type="date"
            {...register(fieldKey, {
              required: requiredProp,
            })}
          />
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Radio:
      return (
        <>
          <div className="flex gap-4">
            {options?.map((option) => (
              <label className="flex items-center gap-1">
                <input
                  type="radio"
                  value={option.key}
                  className="text-sm"
                  {...register(fieldKey, {
                    required: requiredProp,
                  })}
                />
                {option.name}
              </label>
            ))}
          </div>
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Dropdown:
      return (
        <>
          <select
            className="w-full p-2 rounded border border-grey-3"
            {...register(fieldKey, {
              required: requiredProp,
            })}
          >
            {options?.map((option) => (
              <option key={option.key} value={option.key}>
                {option.name}
              </option>
            ))}
          </select>
          <p className="text-red-500">{errors}</p>
        </>
      );
  }
};

const getFieldValue = (field: {
  key: string;
  type: FormInput;
  options?: { key: string; name: string }[];
}) => {
  let value = "N/A";

  if (DYMMY_API_RESPONSE[field.key])
    if ([FormInput.Dropdown, FormInput.Radio].includes(field.type))
      value =
        field.options?.find(
          (option) => option.key == DYMMY_API_RESPONSE[field.key]
        )?.name || "N/A";
    else value = DYMMY_API_RESPONSE[field.key];

  return value;
};
