import React from "react";

const PredictorResultCollegeCard = () => {
  return (
    <div className="w-[50%] border border-[#f7f7f7] shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col p-2 bg-[#f7f7f7] gap-2">
        <span>B.Tech Computer Science and Engineering</span>
        <p className="text-sm text-gray-500">Gen Fees ₹ 5.72 Lakhs | Seats 115</p>
      </div>
      <div className="p-2">
        <div className="flex flex-col items-center justify-center">
          <span>Good Chances</span>
          <p className="text-sm text-gray-500">Last Year’s Cut-off AI Rank : 1988</p>
        </div>
      </div>
    </div>
  );
};

export default PredictorResultCollegeCard;
