import React, { useEffect, useState } from "react";

import dc1 from "../../images/dream-careers/dc1.png";
import dc2 from "../../images/dream-careers/dc2.png";
import dc3 from "../../images/dream-careers/dc3.png";
import dc4 from "../../images/dream-careers/dc4.png";
import dc5 from "../../images/dream-careers/dc5.png";
import dc6 from "../../images/dream-careers/dc6.png";

import "./DreamCareers.scss";
import axios from "axios";
import { Link } from "react-router-dom";

import config from "../../util/config";

export default function DreamCareer() {
  const [featuredImagesCarosal, setfeaturedImagesCarosal] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/career/get-featured-careers"
        );
        setfeaturedImagesCarosal(response.data);
      } catch (error) {
        console.error("Error making API request:", error);
      }
    };

    fetchData();
    return () => {};
  }, []);
  return (
    <div className="my-[40px]">
      <div className="flex flex-col px-5 py-5 w-[1360px] mx-auto">
        <div className="self-center text-3xl font-extrabold text-center mb-6 text-slate-800">
          Dream Careers
        </div>
        <div className="flex justify-between flex-wrap">
          {featuredImagesCarosal.map((item: any, index: number) => (
            <Link key={index} to={"/careers/career-details/" + item.careerName}>
              <div className="dc-card">
                <div className="dc-img">
                  <img src={item.iconUrl} alt="" loading="lazy" />
                </div>
                <div className="dc-content">
                  <h3>{item.careerName}</h3>
                  <p>{item.careerDescriptionShort}</p>
                  <button className="arrow-button">
                    View details
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="currentColor"
                      className="arrow-icon"
                    >
                      <path
                        d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="dc-btn-section text-center ">
          <a
            href="/careers"
            className="arrow-button btn-dc white-color map-mentor  button-styles"
          >
            View all careers
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg> */}
            {/* You can customize the arrow icon here */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
