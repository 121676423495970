import React from "react";
import PredictorResultCollegeCard from "../components/PredictorResultCollegeCard";

const PredictorResultCard = () => {
  return (
    <div className="w-full bg-white border border-black shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col gap-3 p-4 border-b-2 border-black">
        <span>National Institute of Technology Karnataka Surathkal</span>
        <span>Ownership : Public | Government</span>
      </div>
      <div className="p-4">
        <span>Your chances are GOOD in 11 out of 11 Courses</span>
        <PredictorResultCollegeCard />
      </div>
      <div className="flex flex-row p-4">
        <div className="flex gap-2 grow">
          <button className="">View Cutoffs</button>
          <button className="">Download Brochure</button>
        </div>
        <div>
          <button className="">Add to Compare</button>
        </div>
      </div>
    </div>
  );
};

export default PredictorResultCard;
