import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import "./TypesOfScholarships.scss";
import { useMediaQuery } from 'react-responsive';

interface TypeScholarshipProps {
  image: string;
  type: string;
}


const TypeOfScholarships: React.FC = () => {
  const scholarshipTypes = useSelector((state: RootState) => state.scholarship.scholarshipTypes);

  
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' }); 
  const displayedScholarshipData = isLargeScreen ? scholarshipTypes.slice(0, 8) : scholarshipTypes.slice(0, 4);
  if (!scholarshipTypes ) {
    return null; 
  }

  return (
    <div className="py-12 bg-[#E9EBF8]">
      <div className="container">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12">
      {displayedScholarshipData.map((item: TypeScholarshipProps, index: number) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg"
              style={{ width: "305px" }}
            ><div className="w-full h-[207px]">
              <img src={item.image} alt={item.type} className="w-full h-[207px] object-cover rounded-t-lg" /></div>
              <div className="font-bold text-[14px] text-center bg-white py-8">{item.type}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypeOfScholarships;
