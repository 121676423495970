import React, { useState } from "react";
import "./DreamColleges.scss";
import { Card } from "../../../../components/card/Card";
import "animate.css";
// import CollegeIntermediate from "../../../colleges/CollegeIntermediate";
import { useNavigate } from "react-router-dom";

const dreamCollegesData = [
  {
    id: 1484,
    image: "/images/dream-colleges/iit-hyderabad.png",
    title: "SRM University Chennai",
  },
  {
    id: 1485,
    image: "/images/dream-colleges/bits-pilani.png",
    title: "JNU Delhi",
  },
  {
    id: 1486,
    image: "/images/dream-colleges/vit-vellore.png",
    title: " GITAM School of Pharmacy",
  },
  {
    id: 1487,
    image: "/images/dream-colleges/amrita-coimbatore.png",
    title: " IIT Madras",
  },
  {
    id: 1488,
    image: "/images/dream-colleges/bml-munjal.png",
    title: " CBIT Hyderabad",
  },
  { image: "/images/dream-colleges/op-jindal.png", title: " OP JINDAL" },
  { image: "/images/dream-colleges/bml-munjal.png", title: " BML MUNJAL" },
  { image: "/images/dream-colleges/op-jindal.png", title: " OP JINDAL" },
];

export const DreamColleges = () => {
  const [showAll, setShowAll] = useState(false);
  const [buttonText, setButtonText] = useState("View all colleges");

  const navigate = useNavigate();
  const handleViewAllClick = () => {
    navigate("/college-intermediate");
  };

  return (
    <div className="dream-colleges-section py-[60px]">
      <div className="w-[1360px] mx-auto flex flex-col gap-10">
        <h2 className="dc-heading">Dream colleges</h2>
        <div className="flex  flex-wrap gap-[24px]">
          {showAll
            ? dreamCollegesData.map((college) => {
                return <Card {...college} />;
              })
            : dreamCollegesData.slice(0, 8).map((college) => {
                return <Card {...college} />;
              })}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          className={`text-base  py-3.5 px-[34px] white-color map-mentor  button-styles text-white font-semibold rounded-full flex flex-row items-center arrow-button justify-center gap-2`}
          onClick={handleViewAllClick}
        >
          {buttonText}

          {/* You can customize the arrow icon here */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
