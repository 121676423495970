import React from "react";

const StudyAbroadOptions: React.FC = () => {
  const countries = [
    {
      name: "CANADA",
      imageSrc: "/images/canada.png",
    },
    {
      name: "UK",
      imageSrc: "/images/uk.png",
    },
    {
      name: "USA",
      imageSrc: "/images/usa.png",
    },
    {
      name: "AUSTRALIA",
      imageSrc: "/images/australia.png",
    },
    {
      name: "GERMANY",
      imageSrc: "/images/germany.png",
    },
    {
      name: "UAE",
      imageSrc: "/images/uae.png",
    },
  ];

  return (
    <div className="flex flex-col mb-6">
      <h1 className="self-center ml-3 pt-6 text-4xl font-bold text-center text-black">
        Study Abroad Options
      </h1>
      <div className="mt-24 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col container">
          <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="/images/world-map.png"
              alt="Study abroad options illustration"
              className="w-[756px] h-[457.109px] object-contain max-md:mt-10 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
            <div className="self-stretch my-auto max-md:mt-10">
              <div className="flex gap-5 max-md:flex-col">
                {[0, 1, 2].map((columnIndex) => (
                  <div
                    key={columnIndex}
                    className="flex flex-col gap-8 max-md:ml-0 max-md:w-full"
                  >
                    <div className="flex flex-col grow max-md:mt-6 gap-4">
                      {countries
                        .slice(columnIndex * 2, columnIndex * 2 + 2)
                        .map((country) => (
                          <div key={country.name} className="w-[123px]">
                            <div className="flex flex-col justify-center items-center bg-[#D9D9D9] h-[123px] w-[123px] max-md:px-5">
                              <div className="rounded-full">
                                <img
                                  loading="lazy"
                                  src={country.imageSrc}
                                  alt={`${country.name} flag`}
                                  className="object-contain aspect-square w-[70px]"
                                />
                              </div>
                            </div>
                            <div className="self-center text-sm leading-8 text-center text-black">
                              {country.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyAbroadOptions;
