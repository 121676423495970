import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

import { Autoplay } from "swiper/modules";
import { ImageCard } from "../../../../components/image-card/ImageCard";
import "./CollegesByDestination.scss";

const imagesData = [
  { image: "/images/clg-by-destination/hyderabad.png", city: "Hyderabad" },
  { image: "/images/clg-by-destination/pune.png", city: "Pune" },
  { image: "/images/clg-by-destination/chennai.png", city: "Chennai" },
  { image: "/images/clg-by-destination/coimbatore.png", city: "Coimbatore" },
  {
    image: "/images/clg-by-destination/bhubhaneshwar.png",
    city: "Bhubhaneshwar",
  },
  { image: "/images/clg-by-destination/jaipur.png", city: "Jaipur" },
  { image: "/images/clg-by-destination/varanasi.jpeg", city: "Varanasi" },
  { image: "/images/clg-by-destination/agra.jpeg", city: "Agra" },
  { image: "/images/clg-by-destination/kolkata.jpeg", city: "Kolkata" },
  { image: "/images/clg-by-destination/ahmedabad.jpeg", city: "Ahmedabad" },
  { image: "/images/clg-by-destination/delhi.jpeg", city: "Delhi" },
  { image: "/images/clg-by-destination/mumbai.jpeg", city: "Mumbai" },
];

export const CollegesByDestination: React.FC = () => {
  return (
    <div className="clg-by-destination-main py-20 bg-[#F4F7FB]">
      <div className="flex flex-col items-center justify-center gap-[34px]">
        <h3 className="text-3xl font-extrabold text-center text-[#231F40]">
          Colleges by Destination
        </h3>
        {/* slider section starts */}
        <div className="swiper-section w-[1360px] mx-auto">
          <Swiper
            slidesPerView={6}
            spaceBetween={7}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {imagesData.map((item, index) => (
              <SwiperSlide key={index}>
                <ImageCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex items-center justify-center -mt-24">
            <a
              href="/college"
              className="flex gap-2 z-10 justify-center py-[14px] px-[34px] text-base font-semibold text-white  button-styles rounded-full arrow-button white-color map-mentor "
            >
              <span>View all</span>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg> */}

              {/* You can customize the arrow icon here */}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                className="arrow-icon"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </div>
        {/* slider section ends */}
      </div>
    </div>
  );
};
