import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchLandingData } from "./scholarshipThunk";

interface Option {
  key: string;
  option: string;
}

interface Banner {
  searchScholarship: {
    education: Option[];
    destination: Option[];
  };
  searchLoan: {
    estimatedLoan: Option[];
    degrees: Option[];
  };
}

interface FeaturedScholarship {
  id: string;
  logo: string;
}

interface RightScholarship {
  userLookingFor: {
    image: string;
    lookingFor: string;
  }[];
  types: {
    image: string;
    typeName: string;
  }[];
}

interface ScholarshipType {
  image: string;
  type: string;
}

interface LoanAndCalculators {
  searchLoan: {
    estimatedLoan: Option[];
    Location: Option[];
  };
}

interface Mentor {
  image: string;
  name: string;
  designation: string;
  description: string;
}

interface InspiringStory {
  image: string;
  name: string;
  course: string;
  role: string;
  comments: string;
}

interface News {
  image: string;
  pushlishDate: string;
  description: string;
  title: string;
}

interface Spotlight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
  views: string;
}

interface VisualStorySlide {
  image: string;
  description: string;
  title: string;
}

interface VisualStory {
  image: string;
  slides: VisualStorySlide[];
  pushlishDate: string;
  description: string;
  title: string;
}

interface Article {
  image: string;
  postedOn: string;
  description: string;
  title: string;
}

interface Webinar {
  date: string;
  image: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreKnowledgeHub {
  news: News[];
  spotLight: Spotlight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}

interface FAQItem {
  questions: string;
  answers: string;
}

interface FAQ {
  scholarships: FAQItem[];
  educationLoan: FAQItem[];
}

interface scholarshipSlice {
  banner: Banner;
  featuredScholarships: FeaturedScholarship[];
  rightScholarships: RightScholarship;
  scholarshipTypes: ScholarshipType[];
  loanAndCalculators: LoanAndCalculators;
  mentors: Mentor[];
  inspiringStories: InspiringStory[];
  exploreKnowledgeHub: ExploreKnowledgeHub;
  faq: FAQ;
  landingDataLoading: boolean;
  landingDataError: string | null;
}

const initialState: scholarshipSlice = {
  banner: {
    searchScholarship: {
      education: [],
      destination: [],
    },
    searchLoan: {
      estimatedLoan: [],
      degrees: [],
    },
  },
  featuredScholarships: [],
  rightScholarships: {
    userLookingFor: [],
    types: [],
  },
  scholarshipTypes: [],
  loanAndCalculators: {
    searchLoan: {
      estimatedLoan: [],
      Location: [],
    },
  },
  mentors: [],
  inspiringStories: [],
  exploreKnowledgeHub: {
    news: [],
    spotLight: [],
    visualStories: [],
    articles: [],
    webinar: [],
  },
  faq: {
    scholarships: [],
    educationLoan: [],
  },
  landingDataLoading: false,
  landingDataError: null,
};

const scholarshipSlice = createSlice({
  name: "scholarshipLanding",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<scholarshipSlice>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLandingData.pending, (state) => {
        state.landingDataLoading = true;
        state.landingDataError = null;
      })
      .addCase(fetchLandingData.fulfilled, (state, action) => {
        state.landingDataLoading = false;
        Object.assign(state, action.payload);
      })
      .addCase(fetchLandingData.rejected, (state, action) => {
        state.landingDataLoading = false;
        state.landingDataError =
          action.error.message || "Failed to fetch landing data";
      });
  },
});

export const { update } = scholarshipSlice.actions;
export default scholarshipSlice.reducer;
