import Banner from "./components/Banner"
import FeaturedScholarships from "./components/FeaturedScholarships"
import DreamstoReality from "./components/DreamstoReality"
import OneStopSolution from "./components/OneStopSolution";

import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store/store";
import { useEffect } from "react";
import { fetchLandingData } from "./scholarshipThunk";
import RightScholarship from "./components/RightScholarship";
  import TypeOfScholarships from "./components/TypeOfScholarships";
const ScholarshipLanding = () =>
{
    const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchLandingData());
  }, []);
return (

    <>
    <div>
<Banner/>
<FeaturedScholarships/>
<DreamstoReality/>
<RightScholarship/>
<TypeOfScholarships/>
<OneStopSolution/>
 </div>
    </>
)
}

export default ScholarshipLanding