import ResultsGuidance from "./Cards/ResultsGuidance";
import Summary from "./Cards/Summary";
import ReportDownload from "./Cards/ReportDownload";
import Advertisment from "./Cards/Advertisment";
import PredictorResultCard from "./Cards/PredictorResultCard";
const CollegePredictorResults = () => {
  return (
    <>
      <div className="container flex flex-col max-w-6xl min-h-screen ">
        <Summary />
        <div className="grid w-full max-w-screen-lg grid-cols-1 gap-4 md:grid-cols-12">
          <div className=" sm:col-span-1 md:col-span-4">Filters</div>

          <div className="flex flex-col gap-3 sm:col-span-1 md:col-span-6">
            <ReportDownload />
            <span>Showing 93 Colleges in Joint Seat Allocation Authority</span>
            <PredictorResultCard />
            <ResultsGuidance />
            <Advertisment />
          </div>

          <div className=" sm:col-span-1 md:col-span-2"></div>
        </div>
      </div>
    </>
  );
};

export default CollegePredictorResults;
