import React from "react";
import dm1 from "../../images/dream-mentor/dm1.png";
import dm2 from "../../images/dream-mentor/dm2.png";
import dm3 from "../../images/dream-mentor/dm3.png";
import dm4 from "../../images/dream-mentor/dm4.png";
import dm5 from "../../images/dream-mentor/dm5.png";
import dm6 from "../../images/dream-mentor/dm6.png";
import arrowCircle from "../../images/dream-mentor/arrow-circle.png";
import arrowRight from "../../images/dream-mentor/arrow-right.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./Dream-mentor.scss";
import { useNavigate } from "react-router-dom";
export const dreamImagesCarosal = [
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jenny wilson",
    details: "Vocie Over Artist , Teacher ,Madyapradesh",
  },
  {
    dreamImgCarosal: dm3,
    name: "Robert FOX",
    details: "Vocie Over Artist ,Teacher, Madyapradesh",
  },
  {
    dreamImgCarosal: dm4,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm5,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jenny wilson",
    details: "Vocie Over Artist , Teacher ,Madyapradesh",
  },
  {
    dreamImgCarosal: dm3,
    name: "Robert FOX",
    details: "Vocie Over Artist ,Teacher, Madyapradesh",
  },
  {
    dreamImgCarosal: dm4,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm5,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm1,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
  {
    dreamImgCarosal: dm2,
    name: "Jane Coper",
    details: "Vocie Over Artist , Teacher , Madyapradesh",
  },
];
export default function DreamMentors() {
  const navigate = useNavigate();

  return (
    <div className="dream-mentor-main pt-8">
      <div className="flex justify-center items-center">
        <h3 className="text-black text-3xl font-extrabold">Dream Mentors</h3>
      </div>
      {/* slider section starts */}
      <div className="swiper-section w-[1360px] mx-auto p-8">
        {/* slider section */}
        <div className=" dream-mentor-slider featuredCareers">
          <div className="flex">
            <div className="carousel">
              <div className="slider">
                <div className="slidetrack mentor-card flex">
                  {dreamImagesCarosal.map((item, index) => (
                    <div>
                      <div key={index} className="slide">
                        <img
                          src={item.dreamImgCarosal}
                          alt=" "
                          loading="lazy"
                          className="rounded-2xl"
                        />
                        <div className="mentor-details">
                          <h3 className="mentor-name">{item.name}</h3>
                          <p>{item.details}</p>
                          <button className="arrow-button">
                            View details
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="currentColor"
                              className="arrow-icon"
                            >
                              <path
                                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="btn-section ">
              <button
                onClick={() => navigate("/mentor")}
                className="arrow-button flex gap-2 justify-center white-color map-mentor  button-styles px-9 py-3.5 mt-9 text-base font-semibold text-white  rounded-[50px] max-md:px-5"
              >
                <span>View all mentors</span>

                {/* You can customize the arrow icon here */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* slider section ends */}
      </div>
      {/* slider section ends */}
    </div>
  );
}
