import React from "react";
import "./CareerAssessmentTest.scss";

export default function CareerAssessmentTest({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) {
  return (
    <div className="assessment-section">
      <div className="flex gap-5 mt-[50px] mb-[50px] max-md:flex-col max-md:gap-0 w-[1360px] mx-auto">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
            <div className="text-4xl font-extrabold text-slate-800 max-md:max-w-full">
              Career assessment test
            </div>
            <div className="flex gap-4 mt-11 max-md:flex-wrap max-md:mt-10 w-[1360px] mx-auto">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a79db9fef86bbe236dcda451186dd41bf4a3ff8d28d56bb149ee86fb5c91d3e?"
                className="shrink-0 aspect-square w-[54px]"
              />
              <div className="flex flex-col my-auto max-md:max-w-full">
                <div className="text-xl font-bold text-black max-md:max-w-full">
                  Comprehensive Assessment
                </div>
                <div className="mt-1 text-base font-medium text-neutral-600 max-md:max-w-full">
                  Assessment of your passions,abilities, character traits, and
                  ambitions.
                </div>
              </div>
            </div>
            <div className="flex gap-4 max-md:flex-wrap">
              <div className="flex flex-col items-center">
                <div className="shrink-0 w-1 border border-dashed bg-zinc-800 border-zinc-800 h-[63px]" />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1fc582c10c8d2b8a6c68734aa92b96c9ad538049553045a228a9a6fa4dac9c5b?"
                  className="mt-1 aspect-[1.08] w-[58px]"
                />
                <div className="shrink-0 w-1 border border-dashed bg-zinc-800 border-zinc-800 h-[63px]" />
              </div>
              <div className="flex flex-col grow shrink-0 my-auto basis-0 w-fit max-md:max-w-full">
                <div className="text-xl font-bold text-black max-md:max-w-full">
                  Personalized Report
                </div>
                <div className="mt-1 text-base font-medium text-neutral-600 max-md:max-w-full">
                  Find your strengths, preferences and exclusive career avenues
                  that match your distinct qualities
                </div>
              </div>
            </div>
            <div className="flex gap-4 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1699f659deae74d98278da49f45762ba39c54cc245602bf26f279960da8f311e?"
                className="shrink-0 aspect-square w-[54px]"
              />
              <div className="flex flex-col my-auto max-md:max-w-full">
                <div className="text-xl font-bold text-black max-md:max-w-full">
                  Expert Mentorship
                </div>
                <div className="mt-1 text-base font-medium text-neutral-600 max-md:max-w-full">
                  Discover Dream Career College, and Job through Expert Dual
                  Mentorship.
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-9 text-base font-semibold text-white max-md:flex-wrap">
              <button className="arrow-button flex flex-auto gap-3 justify-center px-9 py-3.5  button-styles rounded-[50px] max-md:px-5 white-color map-mentor">
                Start Assessment
                {/* You can customize the arrow icon here */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button className="flex flex-auto gap-2 justify-center px-9 py-3.5 bg-[#0C9] hover:bg-[#0c9] button-styles rounded-[50px] max-md:px-5">
                Download Sample Reports
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <g clip-path="url(#clip0_944_592)">
                    <path
                      d="M8.23169 15.1017C8.46386 15.334 8.73953 15.5183 9.04295 15.644C9.34636 15.7698 9.67159 15.8345 10 15.8345C10.3285 15.8345 10.6537 15.7698 10.9571 15.644C11.2605 15.5183 11.5362 15.334 11.7684 15.1017L14.4442 12.4258C14.5877 12.2672 14.6646 12.0594 14.6591 11.8455C14.6536 11.6317 14.5661 11.4282 14.4147 11.2771C14.2633 11.126 14.0595 11.0389 13.8457 11.0338C13.6318 11.0288 13.4242 11.1062 13.2659 11.25L10.8275 13.6892L10.8334 0.833333C10.8334 0.61232 10.7456 0.400358 10.5893 0.244078C10.433 0.0877974 10.221 0 10 0V0C9.77901 0 9.56705 0.0877974 9.41077 0.244078C9.25449 0.400358 9.16669 0.61232 9.16669 0.833333L9.15919 13.6733L6.73419 11.25C6.57783 11.0937 6.36579 11.006 6.14473 11.0061C5.92367 11.0062 5.7117 11.0941 5.55544 11.2504C5.39919 11.4068 5.31145 11.6188 5.31152 11.8399C5.3116 12.0609 5.39949 12.2729 5.55586 12.4292L8.23169 15.1017Z"
                      fill="currentColor"
                    />
                    <path
                      d="M19.1667 13.3333C18.9457 13.3333 18.7337 13.421 18.5774 13.5773C18.4211 13.7336 18.3333 13.9456 18.3333 14.1666V17.4999C18.3333 17.7209 18.2455 17.9329 18.0893 18.0892C17.933 18.2454 17.721 18.3332 17.5 18.3332H2.5C2.27899 18.3332 2.06702 18.2454 1.91074 18.0892C1.75446 17.9329 1.66667 17.7209 1.66667 17.4999V14.1666C1.66667 13.9456 1.57887 13.7336 1.42259 13.5773C1.26631 13.421 1.05435 13.3333 0.833333 13.3333C0.61232 13.3333 0.400358 13.421 0.244078 13.5773C0.0877974 13.7336 0 13.9456 0 14.1666L0 17.4999C0 18.163 0.263392 18.7988 0.732233 19.2677C1.20107 19.7365 1.83696 19.9999 2.5 19.9999H17.5C18.163 19.9999 18.7989 19.7365 19.2678 19.2677C19.7366 18.7988 20 18.163 20 17.4999V14.1666C20 13.9456 19.9122 13.7336 19.7559 13.5773C19.5996 13.421 19.3877 13.3333 19.1667 13.3333Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_944_592">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="career-animation flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <div
            className="relative -translate-x-12"
            ref={animationCheckPointRef}
          ></div>
          <div className="flex flex-col justify-center ">
            <div className="jss-parent">
              <div className="jss27 jss28">
                <div className="jss29">
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      overflow: "hidden",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: 1,
                      border: 0,
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <img src="/images/center-image.png" alt="" />
                  </span>
                </div>
              </div>
              <div className="jss27 jss30">
                <div className="jss27 jss44"></div>
                <div className="jss27 jss45"></div>
              </div>
              <div className="jss27 jss31">
                <div className="jss27 jss43"></div>
              </div>
              <div className="jss27 jss32"></div>
              <div className="jss27 jss33">
                <div className="jss35">
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      overflow: "hidden",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: 1,
                      border: 0,
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <img
                      sizes="100vw"
                      src="/images/clerk.png"
                      alt=""
                      decoding="async"
                      data-nimg="fill"
                      className="lady-image"
                    />
                  </span>
                </div>
              </div>
              <div className="jss27 jss42">
                <span className="span42">
                  <img
                    className="india-logo"
                    sizes="100vw"
                    alt=""
                    src="/images/india-flag.png"
                    decoding="async"
                    data-nimg="fill"
                  />
                </span>
              </div>
              <div className="jss27 jss39 jss40"></div>
              <div className="jss27 jss39 jss41"></div>
              <div className="jss36 jss37">
                <p>
                  <span>Clarke</span> is an alumni from Oxford University
                </p>
              </div>
              <div className="jss36 jss38">
                <p>
                  <span>Arnav</span> looking forword to join Oxford University
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
